<template>
  <div class="body-box"  v-track="{ event: 'browse', route: $route, isButton: false }">
    <div class="top-box">
      <div class="item-box">
        <img :src="cardObj.processTask.img" alt="">
        <div class="text-box">
          <p class="tip" style="color: #4CA0FF;">{{ cardObj.processTask.text }}</p>
          <p><span class="num">{{ cardObj.processTask.num }}</span> 个</p>
        </div>
      </div>
      <div class="item-box">
        <img :src="cardObj.finishTask.img" alt="">
        <div class="text-box">
          <p class="tip" style="color: #57C694;">{{ cardObj.finishTask.text }}</p>
          <p><span class="num">{{ cardObj.finishTask.num }}</span> 个</p>
        </div>
      </div>
      <div class="item-box">
        <img :src="cardObj.totalTask.img" alt="">
        <div class="text-box">
          <p class="tip" style="color: #FF7F5F;">{{ cardObj.totalTask.text }}</p>
          <p><span class="num">{{ cardObj.totalTask.num }}</span> 个</p>
        </div>
      </div>
    </div>
    <div class="main-content" v-loading="pageLoad">
      <Row :gutter="20" index="">
        <Col span="24">
        <Form ref="searchParam" :model="searchParam" label-position="right" :label-width="70" inline>
          <FormItem label="任务名称" :label-width="70">
            <Input type="text" clearable maxlength="50" style="width: 200px;" v-model="searchParam.taskName"
              placeholder="请输入">
            </Input>
          </FormItem>
          <FormItem label="状态" style="margin-right: 48px;">
            <Select clearable v-model="searchParam.state" style="width:200px;">
              <Option value="0" label="处理中" key="0"></Option>
              <Option value="1" label="已完成" key="1"></Option>
            </Select>
          </FormItem>
          <FormItem label="提交时间" style="margin-right: 48px;">
            <DatePicker format="yyyy-MM-dd" style="width: 200px;" type="daterange" placement="bottom-end"
              @on-change='getCreateTimeSelectDate' placeholder="请选择"></DatePicker>
          </FormItem>
          <FormItem label="完成时间">
            <DatePicker format="yyyy-MM-dd" style="width: 200px;" type="daterange" placement="bottom-end"
              @on-change='getCompletionTimeSelectDate' placeholder="请选择"></DatePicker>
          </FormItem>
          <FormItem :label-width="24">
            <Button type="primary"  @click="search">
            查询</Button>
          </FormItem>
        </Form>
        </Col>

      </Row>
      <Row :gutter="6" style="margin-bottom: 20px;" v-if="cardObj.totalTask.num > 0">
        <Col span="">
        <Button type="primary" @click="handleAdd">新增估价任务 </Button>

        </Col>
      </Row>
      <!-- <div  class="in-process-box">
       <div v-loading="loading" class="loading-box"></div>
       <div>处理中</div>
      </div> -->
      <Row :gutter="20">
        <Col :span="24" v-if="cardObj.totalTask.num == 0">
        <div class="empty-box">
          <img :src="emptyImg" alt="">
          <p>无任务</p>
          <Button type="primary" @click="handleAdd">新增估价任务 </Button>
        </div>
        </Col>
        <Col :span="24" v-if="cardObj.totalTask.num > 0">
        <Table no-data-text="暂无符合条件结果" :columns="columns" v-loading="isLoading" :data="tableData">
          <template slot-scope="{ row }" slot="taskName">
            <Tooltip max-width="400" :transfer="true">
              <div class="taskName-box"
              style="color:#0077FF ;"
                @click="handleDetails(index, row)">
                <div class="red-point" v-if=" row.hasRedPoint"></div>
                {{ row.taskName | filter_evlTitle }}
              </div>
              <div slot="content">
                <div>{{ row.taskName }}</div>
              </div>
            </Tooltip>
          </template>
         <template slot-scope="{ row }" slot="serialNumber" >
            <Tooltip max-width="400" :transfer="true">
              <div v-copy="copyText(row.serialNumber) " @click="copyBit">{{ row.serialNumber }} </div>
              <div slot="content">
                <div > 点击复制</div>
              </div>
            </Tooltip>
          </template>
          <template #action="{ row, index }">
            <el-button  type="text" @click="handleDetails(index, row)">详情</el-button>
            <el-button  type="text"   v-if="row.state === '1'"  @click="handleDel(index, row)">
            <span >删除</span>
            </el-button>
          </template>
        </Table>
        </Col>
      </Row>
      <Row :gutter="16" style="margin-top: 24px;" type="flex" justify="flex-start">

        <el-pagination :current-page="pageable.current" :page-size="pageable.size" :hide-on-single-page="isShowPage"
          background layout="prev, pager, next, jumper" :total="pageable.total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />

      </Row>
    </div>
    <taskSubmitModal :visible="visible" @changeDialog="changeDialog"></taskSubmitModal>
  </div>
</template>

<script  setup>
import { reactive, ref,getCurrentInstance,onBeforeMount } from "vue"
import { userBatchTaskManagement, removeTask, taskStatusStatistics } from '@/api/pledge'
import {
  formatTime,
  formatDate,
  _debounce
} from "@/utils/";
import { useTable } from '@/hooks/useTable';
import { useHandleData } from '@/hooks/useHandleData';

import { dataReports } from '@/hooks/useDataReports';
import { useStore, useRouter, useRoute } from "@/utils/vueApi.js";
import taskSubmitModal from "./components/taskSubmitModal.vue";
const { proxy } = getCurrentInstance()

dataReports()
// mixins: [dataReports]
const loading = ref(true);
//--------------头部卡片-----------------
const cardObj = reactive({
  processTask: {
    text: '处理中',
    num: 0,
    img: require("@/assets/image/task/inProgress.png"),
  },
  finishTask: {
    text: '已完成',
    num: 0,
    img: require("@/assets/image/task/complete.png"),
  },
  totalTask: {
    text: '任务总数',
    num: 0,
    img: require("@/assets/image/task/tasksNum.png"),
  },
})
const pageLoad =ref(true)

async function getCardData() {
  let res = await taskStatusStatistics()
  pageLoad.value = false
  cardObj.processTask.num = res.processTask;
  cardObj.finishTask.num = res.finishTask;
  cardObj.totalTask.num = res.totalTask;
}
// getCardData();
//--------------查询条件-----------------
const getCreateTimeSelectDate = (val) => {
  initParam.createTimeStart = val[0];
  initParam.createTimeEnd = val[1];
}
const getCompletionTimeSelectDate = (val) => {
  initParam.completionTimeStart = val[0];
  initParam.completionTimeEnd = val[1];
}
const initParam = {}
//--------------表格相关-----------------
const emptyImg = require("@/assets/image/task/empty.png");
//表头
const columns = ref([
  {
    title: '序号',
    key: "index",
    width: 100,
  },
  {
    title: '流水号',
    key: "serialNumber",
  slot:'serialNumber',
  },


  {
    title: '任务名称',
    key: 'taskName',
    width: 250,
    tooltip: true,
    slot: 'taskName',
  },
  {
    title: '数据量',
    key: 'taskCnt',

  },
  {
    title: '状态',
    key: 'state',
    render: (h, params) => {
      return h(
        "span",
        // params.row.state === '0' ? '处理中' : '已完成'
        params.row.state === '1' ? '已完成' : '处理中'
      );
    },

  },

  {
    title: "提交时间",
    key: "createTime",
    render: (h, params) => {
      return h(
        "span",
        params.row.createTime ? formatTime(params.row.createTime, '/') : formatTime(
          params.row.createTime, '/')
      );
    },
  },

  {
    title: "完成时间",
    key: "completionTime",
    render: (h, params) => {
      return h(
        "span",
        params.row.completionTime ? formatTime(params.row.completionTime, '/') : ''
      );
    },
  },
  {
    title: '操作',
    slot: 'action'
  },
])
const {
  tableData,
  pageable,
  searchParam,
  searchInitParam,
  isShowPage,
  getTableList,
  search,
  reset,
  handleSizeChange,
  handleCurrentChange,
  isLoading,
} = useTable(userBatchTaskManagement, initParam);
const router = useRouter();
//跳转详情页
function handleDetails(index, row) {
  // if (row.state == 0) {//处理中 不可点击跳转详情页
  //   return
  // }
  router.push({
    path: '/Empowerment/pledge/pledgeManage/taskDetail',
    query: {
      id: row.id,
      serialNumber: row.serialNumber,
      taskName: row.taskName,
      state: row.state,
    },
  });
}

//删除
const handleDel = async (index, row) => {

  const params = {
    taskId: row.id,
  };
  await useHandleData(removeTask, params, '确定删除该任务？确定后，该任务的估价记录也将删除');
  getCardData()

  search();
};

const copyText = (text) => {
          // proxy.$msg.success({ text: '复制成功' });

 return text
}
const  copyBit = () => {
  proxy.$msg.success({ text: '复制成功' });
}
//--------------弹窗相关-----------------

const visible = ref(false);
const handleAdd = () => {
  visible.value = true;
}
const changeDialog = (val) => {
  visible.value = val;
  search();
  getCardData()
}

onBeforeMount(() => {
  getCardData();
});

</script>

<style lang="scss" scoped>
.in-process-box {
  width: 56px;
  height: 56px;
  border-radius: 11.2px;
  opacity: 1;

  background: #ECF5FF;

}

.body-box {
  background: #EFF1F6;
  // height: 100%;
  min-height: 100%;
  padding: 24px;
  min-width: 1200px;

  .top-box {
    height: 100%;
    display: flex;
    justify-content: space-between;
    height: 130px;
    margin-bottom: 24px;

    .item-box {
      height: 100%;
      background: #FFFFFF;
      border-radius: 12px;
      width: 32.3%;
      max-width: 528px;
      display: flex;
      padding: 30px 48px;
      align-items: center;

      img {
        width: 72px;
        height: 72px;
      }

      .text-box {
        margin-left: 24px;
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: normal;
        line-height: 34px;
        letter-spacing: 0px;
        color: #404040;

        .tip {
          font-family: PingFang SC;
          font-size: 18px;
        }

        .num {
          font-weight: 600;
          font-family: DIN-Bold, DIN;
          font-size: 40px;

        }
      }
    }
  }

  .main-content {
    background: #FFFFFF;
    height: 100%;
    border-radius: 10px 10px 10px 10px;
    padding: 24px;

    min-height: 810px;
    // margin: 24px;
  }

  .empty-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      color: #A6A6A6;
      margin: 20px 0;
    }
  }

  .taskName-box {
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    font-weight: normal;
    color: #0077FF;
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .red-point {
      background: red;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      float: left;
      position: absolute;
      left: -13px;
      top: 5px;

    }
  }
}
</style>
