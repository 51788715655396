import {
    onMounted,
    toRefs,
    ref,
    onBeforeUnmount,
    getCurrentInstance
} from 'vue';
import { useStore,  useRoute } from "@/utils/vueApi.js";

export const dataReports = () => {
const { proxy } = getCurrentInstance()

    const currentPageId = ref('')
const route = useRoute();

    const getPageId = () => {
        return route.meta.pageId;
    }
    const enterPagelog = () => {
        const pageId = getPageId();
        currentPageId.value = pageId
        sessionStorage.setItem('pageId', pageId)
        if (pageId) {
            proxy.$pagelog(pageId, 'enter', proxy);
        }
    }
    const leavePageLog = () => {
        const lastEnterPageTime = sessionStorage.getItem('lastEnterPageTime')
        const enterPageTime = sessionStorage.getItem('enterPageTime')

        // const pageId = +sessionStorage.getItem('pageId');
        const pageId = currentPageId.value;
        if (pageId && enterPageTime != lastEnterPageTime) {
            return proxy.$pagelog(pageId, 'leave', proxy);
        }
    }
    const closePageLog = () => {
        leavePageLog()
    }
    const beforeunloadHandler = (e) => {
        const pageId = sessionStorage.getItem('pageId');
        proxy.$pagelog(pageId, 'leave', proxy, true)
    }
    onMounted(() => {
        enterPagelog()
        window.addEventListener('beforeunload', e => beforeunloadHandler(e))
    })
    enterPagelog()
    onBeforeUnmount(() => {
        leavePageLog();

    })

}