<template>
  <Modal title="提示" v-model="dialogVisible" width="878" :footer-hide="true" :mask-closable="false"
    :before-close="handleClose">
    <div>
      <Row :gutter="6" index="">
        <div class="left-box">
          <div class="title">第1步：下载模板</div>
          <div class="img-box">
            <img src="../../../assets/image/task/xlsx.png" alt="">
          </div>
          <div class="tips">待估房屋数据模板.xlsx</div>
          <Button type="primary" @click="downExcel" style="width: 88px;">下载模板 </Button>
        </div>
        <div class="right-box-dil ">
          <div class="title" style="margin-left: 42px;">第2步：提交批量估价任务</div>
          <el-form ref="ruleFormRef" @submit.native.prevent style="width: 100%;" :model="formData" :rules="rules"
            size="medium" label-width="100px">
            <div class="el-upload__text el-upload-tips">当前仅支持商品住宅估价</div>
            <el-form-item label="上传文件" style="max-width: 360px;" prop="uploadNumber" required>
              <el-upload v-loading="uploadIng" :show-file-list="false" element-loading-text="上传中" class="upload-demo"
                style="width: 360px;" ref="editUploadRef" drag :action="file_conf.uploadUrl" :on-success="onUploadSuccess"
                :on-error="onUploadError" :before-upload="beforeUpload" :headers="file_conf.headers"
                :file-list="uploadFileList" :on-change="handleChange" accept=".xlsx" multiple>
                <img class="upload-img" src="@/assets/image/task/upload.png" alt="" srcset="">
                <div class="el-upload__text" style="font-size: 12px;">
                  请按模板将文件拖到此处，或 <em>点击上传数据</em>,格式为.xlsx
                  <div style="margin-top: -8px;">（文件大小不超过20M）</div>
                </div>
              </el-upload>

              <div v-if="showFileName">{{ fileName }}</div>
              <el-progress v-if="showPercentage" style="width: 375px;" stroke-width="3"
                :percentage="percentage"></el-progress>
              <div style="color: red;" v-if="showErrMsg" @click="downErrMmsg">文件校验不通过，<a> 下载错误原因</a></div>
              <div style="color: red;" v-if="showResErrorMsg"> {{ resErrorMsg }}</div>
            </el-form-item>

            <el-form-item label="任务名称" style="max-width: 360px;" prop="taskName">
              <Input maxlength="20" style="width: 360px;" @keyup.enter.native="submit" v-model="formData.taskName"
                placeholder="批量+日期+编号" show-word-limit />
            </el-form-item>
            <div class="right-bit-box">
              <Button type="primary" :loading="subBitDisabled" style="width: 88px;" @click="submit">提交</Button>
            </div>
          </el-form>
        </div>


      </Row>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="comfirmBtn">确 定</el-button>
  </span> -->
  </Modal>
</template>

<script  setup>
import { reactive, ref, computed, getCurrentInstance, onMounted, watch, nextTick } from "vue"
import { MessageBox } from 'element-ui';
import { _file_conf } from '@/api/public.js'
import { downloadErrorMsg, submitBatchEvlTask } from '@/api/pledge'
import util from "@/utils/utils2";

const { proxy } = getCurrentInstance()

const downExcel = () => {
  window.open("https://www.zhongsituo.com/group2/M00/02/45/wKgCGmQTBe6ADZuXAAEsRlTcFWc9616718?filename=%E5%BE%85%E4%BC%B0%E6%88%BF%E5%B1%8B%E6%95%B0%E6%8D%AE%E6%A8%A1%E6%9D%BF.xlsx")

}
const uploadIng = ref(false)//是否正在上传
// const upLoading = ref(false)//是否正在上传
//文件上传
let file_conf = _file_conf
file_conf.uploadUrl = `${file_conf.host}/service-analyze/EvlBatchExcelUpload/inputHouseUpload`
file_conf.maxSize = 20 * 1024 * 1024
file_conf.headers = {
  "Authorization": localStorage.getItem("token")
}
const handleClose = () => {
  if (uploadIng.value) {
    return new Promise((resolve, reject) => {
      MessageBox.confirm(`文件上传中，该操作会取消文件上传，确定关闭弹框？`, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        draggable: true
      }).then(async () => {
        resolve(true);
        proxy.$refs.editUploadRef.abort()

      }).catch(() => {
        reject(false);
      })
        ;
    });
  } else {
    return true
  }

}
const errNname = ref('')
const downErrMmsg = async () => {
  let params = {
    fileName: errNname.value
  }
  downloadErrorMsg(params).then((res) => {
    if (res.data.type === 'application/json') {
      const reader = new FileReader()
      reader.readAsText(res.data)
      reader.onload = () => {
        //json字符串转json对象
        const resText = JSON.parse(reader.result)
        if (resText.code === -1) {
          proxy.$msg.error({ text: resText.data })
          return
        }
      }
    } else {
      if (res.hasOwnProperty("headers")) {
        //调用封装好的下载方法
        util.downloadBlod(res);
      } else {
        //将错误信息返回
        let blob = new Blob([res]);
        blob.text().then((res) => {

        });
      }
    }

  })


  // util.downloadFile(res)
}
let uploadFileList = ref([])
const handleChange = (file, fileList) => {
  // uploadFileList.value = []
  uploadFileList.value = fileList.slice(-1)

}
//进度条
const showPercentage = ref(false)
const percentage = ref(0);
// const strokeWidth = ref(10);
// const color = ref('#409EFF');
let timer = null;

function startProgress() {
  percentage.value = 0;
  timer = setInterval(() => {
    if (percentage.value < 90) {
      percentage.value += 1;
    } else {
      clearInterval(timer);
    }
  }, 200);
}

const fileName = computed(() => {
  if (uploadFileList.value.length > 0) {
    return uploadFileList.value[0].name
  } else {
    return ''
  }
})
const showFileName = computed(() => {
  if (uploadFileList.value.length > 0) {
    return true
  } else {
    return false
  }
})
const beforeUpload = (file) => {
  // uploadFileList.value = []
  //表单校验重置
  proxy.$refs.ruleFormRef.clearValidate()

  if (file.size > file_conf.maxSize) {
    proxy.$msg.error({ text: `上传文件不超过20M` });
    proxy.$refs.editUploadRef.clearFiles()

    return false;
  }
  let extname = file.name.substring(file.name.lastIndexOf('.') + 1);
  if (extname.indexOf('xlsx') !== -1) {
    showErrMsg.value = false
    uploadIng.value = true
    showPercentage.value = true //进度条
    startProgress()

    return true;

  } else {
    proxy.$msg.error({ text: `格式不对，请下载模板上传` });
    nextTick(() => {
      proxy.$refs.editUploadRef.clearFiles()
    })

    return false
  }


  // return true;
}
const resErrorMsg = ref('')
const onUploadError = () => {//上传失败
  showPercentage.value = false//进度条
  uploadIng.value = false
  showResErrorMsg.value = true
  proxy.$msg.error({ text: '上传失败' })
  resErrorMsg.value = '上传失败'

  proxy.$refs.editUploadRef.clearFiles()



};
const showErrMsg = ref(false)//是否显示下载错误信息
const showResErrorMsg = ref(false)//是否显示错误信息
//x上传成功
const onUploadSuccess = (res, file, fileList) => {
  subBitDisabled.value = false
  uploadIng.value = false
  setTimeout(() => {
    showPercentage.value = false//进度条
  }, 1000)
  if (res.code == -1) {
    proxy.$msg.error({ text: res.data })
    proxy.$refs.editUploadRef.clearFiles()


    return
  }
  if (res.code == 0) {
    formData.uploadNumber = res.data.uploadNumber
    formData.filename = res.data.filename
    // formData.uploadSize = res.data.uploadSize
    proxy.$msg.success({ text: '上传成功' })
    percentage.value = 100
    return
  }
  else if (res.code == 2021081001 || res.code == 100005) {
    proxy.$msg.error({ type: 'fromEnd', code: res.code })
    showErrMsg.value = true
    errNname.value = res.data
    proxy.$refs.editUploadRef.clearFiles()
    showPercentage.value = false//进度条

    return
  }

  else {
    proxy.$msg.error({ type: 'fromEnd', code: res.code })
    proxy.$refs.editUploadRef.clearFiles()
    showPercentage.value = false//进度条

  }

}
//表单校验
const rules = reactive({
  uploadNumber: [
    { required: true, message: '请上传文件', trigger: 'change' }
  ],
  taskName: [
    { required: true, message: '请输入任务名称', trigger: 'change' }
  ]
})
const subBitDisabled = ref(false)
const submit = () => {
  if (uploadIng.value) {
    proxy.$msg.error({ text: '上传中，请稍候' });
    return
  }
  proxy.$refs['ruleFormRef'].validate((valid) => {
    if (valid) {
      subBitDisabled.value = true
      let params = {
        uploadNumber: formData.uploadNumber,
        taskName: formData.taskName,
        // uploadSize: formData.uploadSize,
        filename: formData.filename,

      }
      submitBatchEvlTask(params).then((res) => {
        subBitDisabled.value = false
        if (res) {
          proxy.$msg.success({ text: '提交成功' });
          emit('changeDialog', false)
        } else {
          proxy.$msg.error({ text: '提交失败' })
        }
      }).catch(() => {
        subBitDisabled.value = false
      })
    } else {
      subBitDisabled.value = false

      // return false;
    }
  });
}
let nowTime = ref('批量' + getNowTime())
const formData = reactive({
  uploadNumber: '',
  filename: '',
  taskName: nowTime,
  uploadSize: null
})
function getNowTime() {//获取当前时间
  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;
  let date = now.getDate();
  let hour = now.getHours();
  let minute = now.getMinutes();
  let second = now.getSeconds();
  let timestamp = `${year}${month}${date}${hour}${minute}${second}`;
  return timestamp
}
const emit = defineEmits(['changeDialog'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})
const init = () => {
  proxy.$refs.ruleFormRef.resetFields()
  proxy.$refs.editUploadRef.clearFiles()
  showErrMsg.value = false
  uploadIng.value = false
  showPercentage.value = false//进度条
  uploadFileList.value = []
  nowTime.value = '批量' + getNowTime()
}
watch(() => props.visible, (val) => {
  if (val) {
    init()
  }
})
const dialogVisible = computed({
  get() {
    return props.visible
  },
  set(val) {
    emit('changeDialog', val)
  }
})
const comfirmBtn = () => {
  dialogVisible.value = false
}
</script>

<style lang="scss" scoped>
.title {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  color: #404040;
  width: 100%;
  text-align: left;
}

.left-box {
  text-align: center;
  // padding: 20px 0;
  width: 296px;
  border-right: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;

  .img-box {
    margin-top: 47px;
    width: 143px;
    height: 143px;
    border: 1px solid #00B470;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .tips {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: normal;
    color: #737373;
    margin-top: 14px;
    margin-bottom: 60px;
  }
}

.right-box-dil {
  margin-left: 60px;

  .el-upload-tips {
    margin: 24px 0 14px 100px;
  }

  .el-upload__text {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: normal;
    color: #BFBFBF;
  }

  .upload-img {
    margin-top: 22px;
    margin-bottom: -12px;
  }

  .right-bit-box {
    text-align: center;
    margin-left: 196px;
  }
}

/deep/ .el-upload-dragger {
  height: 132px;
}

/deep/ .el-upload-list__item:first-child {
  margin-top: 0;
}
</style>

<style>
.el-message-box__wrapper {
  z-index: 999999 !important;
}
</style>