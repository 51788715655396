import { MessageBox } from 'element-ui';
import _this from '../main.js'

/**
 * @description 操作单条数据信息(二次确认【删除、禁用、启用、重置密码】)
 * @param {Function} api 操作数据接口的api方法(必传)
 * @param {Object} params 携带的操作数据参数 {id,params}(必传)
 * @param {String} message 提示信息(必传)
 * @param {String} confirmType icon类型(不必传,默认为 warning)
 * @return Promise
 */
export const useHandleData = (
    api,
    params = {},
    message = '',
    confirmType = "warning"
) => {
    return new Promise((resolve, reject) => {
        MessageBox.confirm(`${message}`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",

            draggable: true
        }).then(async () => {
            const res = await api(params);
            if (!res) return reject(false);
            if (res) {
                _this.$msg.success({
                    text: `操作成功!`
                })
            } else {
                _this.$msg.error({
                    text: '失败'
                })

            }
            resolve(true);
        });
    });
};