import { render, staticRenderFns } from "./taskSubmitModal.vue?vue&type=template&id=389f3598&scoped=true&"
import script from "./taskSubmitModal.vue?vue&type=script&setup=true&lang=js&"
export * from "./taskSubmitModal.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./taskSubmitModal.vue?vue&type=style&index=0&id=389f3598&prod&lang=scss&scoped=true&"
import style1 from "./taskSubmitModal.vue?vue&type=style&index=1&id=389f3598&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "389f3598",
  null
  
)

export default component.exports